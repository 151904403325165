import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Card, Typography, Button, Box } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Footer from '../Policy/Footer';
import './Cart.css'; // Import the CSS file
import { EmptyCart } from "../../Assets/Images/Image";

const CartPage = () => {
  const [cart, setCart] = useState([]);
  const authToken = localStorage.getItem("Authorization");
  const navigate = useNavigate();

  useEffect(() => {
    const getCart = async () => {
      if (authToken) {
        try {
          const { data } = await axios.get(
            `https://api.rubyshoping.online/api/getCart`,
            {
              headers: {
                Authorization: authToken,
              },
            }
          );
          setCart(data.result);
        } catch (error) {
          console.error("Error fetching cart data:", error);
          toast.error("Failed to load cart data", {
            autoClose: 500,
            theme: "colored",
          });
        }
      } else {
        console.log("No auth token found");
      }
    };

    getCart();
  }, [authToken]);

  const handleDelete = async (itemId) => {
    if (authToken) {
      try {
        await axios.get(
          `https://api.rubyshoping.online/api/deleteToCart/${itemId}`,
          {
            headers: {
              Authorization: authToken,
            },
          }
        );
        setCart(cart.filter(item => item.id !== itemId));
        toast.success("Item removed from cart", {
          autoClose: 500,
          theme: "colored",
        });
        window.location.reload();
      } catch (error) {
        console.error("Error removing item from cart:", error);
        toast.error("Failed to remove item from cart", {
          autoClose: 500,
          theme: "colored",
        });
      }
    }
  };

  const calculateTotals = () => {
    let totalQuantity = 0;
    let totalPrice = 0;

    cart.forEach(item => {
      totalQuantity += item.quantity;
      totalPrice += item.quantity * item.price;
    });

    return { totalQuantity, totalPrice };
  };

  const { totalQuantity, totalPrice } = calculateTotals();

  const proceedToCheckout = () => {
    if (cart.length <= 0) {
      toast.error("Please add items in cart to proceed", {
        autoClose: 500,
        theme: "colored",
      });
    } else {
      sessionStorage.setItem("totalAmount", totalPrice.toFixed(2));
      sessionStorage.setItem("cartItems", JSON.stringify(cart));

      navigate("/checkout");
    }
  };

  return (
    <>
      <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        {cart.length > 0 ? (
          <>
            <Box className="wishlist-container">
              {cart.map((product) => (
                <Link to={`/Detail/type/${product?.type}/${product?._id}`} key={product._id}>
                  <Box className="wishlist-item">
                    <img src={product.image} alt={product.name} />
                    <Typography variant="h6">{product.name}</Typography>
                    <Typography variant="h6">price {product.price}</Typography>
                    <Typography variant="h6">Items left {product.items_left}</Typography>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDelete(product._id)}
                    >
                      Remove
                    </Button>
                  </Box>
                </Link>
              ))}
            </Box>

            <Box className="summary-container">
              <Typography variant="h4" component="h1" gutterBottom>
                Order Summary
              </Typography>
              <Card className="summary-card">
                <Typography variant="h6" component="h2" gutterBottom>
                  Cart Summary
                </Typography>
                <Box className="summary-item">
                  <Typography variant="body1">Total Quantity:</Typography>
                  <Typography variant="body1">{totalQuantity}</Typography>
                </Box>
                <Box className="summary-item">
                  <Typography variant="body1">Total Price:</Typography>
                  <Typography variant="body1">₹{totalPrice.toFixed(2)}</Typography>
                </Box>
              </Card>
            </Box>
            <Box sx={{ textAlign: 'center', margin: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={proceedToCheckout}
                sx={{ padding: '10px 20px', fontSize: '16px', marginBottom: '3rem' }}
              >
                Proceed to Checkout
              </Button>
            </Box>
          </>
        ) : (
          
          <Box className="empty-cart-container" >
                        <Typography className="empty-cart-text">Your cart is empty</Typography>
            <img src={EmptyCart} alt="Empty Cart" className="empty-cart-image" />
          </Box>
        )}
      </Box>
      <Footer />
    </>
  );
};

export default CartPage;
