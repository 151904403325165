import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import HomePage from './Pages/Home/HomePage';
import Login from './Auth/Login/Login';
import Register from './Auth/Register/Register';
import Cart from './Pages/Cart/Cart';
import ProductDetail from './Pages/Detail/ProductDetail';
import SingleCategory from './SingleCategory/SingleCategory';
import MobileNavigation from './Navigation/MobileNavigation';
import DesktopNavigation from './Navigation/DesktopNavigation';
import Wishlist from './Pages/WhisList/Wishlist';
import PaymentSuccess from './Pages/Payment/PaymentSuccess';
import { Flip, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CheckoutForm from './Components/Checkout/CheckoutForm';
import UpdateDetails from './Pages/Update_User/UpdateDetails';
import ForgotPasswordForm from './Auth/ForgotPassword/ForgotPasswordForm';
import AddNewPassword from './Auth/ForgotPassword/AddNewPassword';
import SessionCheck from "./SessionCheck";
import PrivacyPolicy from './Pages/Policy/PrivacyPolicy';
import RefundPolicy from './Pages/Policy/RefundPolicy';
import TermsAndConditions from './Pages/Policy/TermsAndConditions';
import ShippingDelivery from './Pages/Policy/Shipping&Delivery';
import Disclaimer from './Pages/Policy/Disclaimer';
import FAQs from './Pages/Policy/FAQs';
import ContactUs from './Pages/ContactUs';
import Pay from './Pages/Payment/Pay';

// import Footer from './Pages/Policy/Footer';

import AdminLogin from './Admin/Auth/Login/AdminLogin';
import AdminRegister from './Admin/Auth/Register/AdminRegister';
import AdminHomePage from './Admin/Pages/AdminHomePage';
import SingleUserPage from './Admin/Pages/SingleUserPage';
import SingleProduct from './Admin/Pages/SingleProduct';
function App() {

  return (

    <>
      <ToastContainer toastClassName='toastContainerBox' transition={Flip} position='top-center' />
      <Router>
        <DesktopNavigation />
        <div className='margin'>
          <Routes>
            {/*User Routes  */}
            <Route path='/' index element={<HomePage />} />
            <Route path="/login" element={< Login />} />
            <Route path='/register' element={<Register />} />

            <Route path='/Detail/type/:cat/:id' element={<ProductDetail />} />

            <Route path='product/type/:cat' element={<SingleCategory />} />
            <Route path="/cart" index element={<SessionCheck> <Cart /> </SessionCheck>} />
            <Route path="/Wishlist" index element={<SessionCheck> <Wishlist /></SessionCheck>} />
            <Route path='/checkout' element={<CheckoutForm />} />
            <Route path='/update' element={<UpdateDetails />} />
            <Route path='/paymentsuccess/:amt/:orderId' element={<PaymentSuccess />} />
            <Route path='/forgotpassword' element={<ForgotPasswordForm />} />
            <Route path='/user/reset/:id/:token' element={<AddNewPassword />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/refund&Cancellation-policy" element={<RefundPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/Shipping&Delivery" element={<ShippingDelivery />} />
            <Route path="/Disclaimer" element={<Disclaimer />} />
            <Route path="/FAQs" element={<FAQs />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="/pay/:amount/:token" element={<Pay />} />

            {/* admin?\ */}

            {/* Admin Routes */}
            <Route path="/admin/login" element={< AdminLogin />} />
            <Route path='/admin/register' element={<AdminRegister />} />
            <Route path='/admin/home' element={<AdminHomePage />} />
            <Route path='/admin/home/user/:id' element={<SingleUserPage />} />
            <Route path='/admin/home/product/:type/:id' element={<SingleProduct />} />

          </Routes>
        </div>
        <MobileNavigation />
      </Router >


    </>
  );
}
export default App;
