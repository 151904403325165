import React, { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import Footer from '../Policy/Footer'

const PasswordUpdate = ({ authToken }) => {
  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const handlePasswordChange = async () => {
    if (passwords.newPassword !== passwords.confirmPassword) {
      toast.error('New passwords do not match', { autoClose: 500, theme: 'colored' });
      return;
    }

    try {
      await axios.post(
        `https://api.rubyshoping.online/api/changePassword`,
        {
          currentPassword: passwords.currentPassword,
          newPassword: passwords.newPassword,
        },
        {
          headers: { Authorization: authToken },
        }
      );
      toast.success('Password changed successfully', { autoClose: 500, theme: 'colored' });
      setPasswords({ currentPassword: '', newPassword: '', confirmPassword: '' });
    } catch (error) {
      toast.error('Failed to change password', { autoClose: 500, theme: 'colored' });
    }
  };

  return (
    <Box sx={{ maxWidth: '400px', margin: '0 auto' }}>
      <TextField
        label='Current Password'
        type='password'
        value={passwords.currentPassword}
        onChange={(e) =>
          setPasswords({ ...passwords, currentPassword: e.target.value })
        }
        fullWidth
        margin='normal'
      />
      <TextField
        label='New Password'
        type='password'
        value={passwords.newPassword}
        onChange={(e) =>
          setPasswords({ ...passwords, newPassword: e.target.value })
        }
        fullWidth
        margin='normal'
      />
      <TextField
        label='Confirm New Password'
        type='password'
        value={passwords.confirmPassword}
        onChange={(e) =>
          setPasswords({ ...passwords, confirmPassword: e.target.value })
        }
        fullWidth
        margin='normal'
      />
      <Button
        variant='contained'
        color='primary'
        sx={{ marginTop: '20px' }}
        onClick={handlePasswordChange}
      >
        Submit
      </Button>
    </Box>
    
  );
  <Footer />

};

export default PasswordUpdate;
