import axios from 'axios';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";

function PaymentSuccess() {
  const [responseId, setResponseId] = React.useState("");
  const [responseState, setResponseState] = React.useState([]);
  const navigate = useNavigate();
  const { amt } = useParams(); // Extracting 'amt' from URL parameters

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const createRazorpayOrder = (amount) => {
    let data = JSON.stringify({
      amount: amount * 100, // Razorpay expects the amount in paise
      currency: "INR"
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.rubyshoping.online/api/razorpay",
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        handleRazorpayScreen(response.data.amount);
      })
      .catch((error) => {
        console.log("error at", error);
      });
  };

  const handleRazorpayScreen = async (amount) => {
    const res = await loadScript("https:/checkout.razorpay.com/v1/checkout.js");

    if (!res) {
      alert("Some error at razorpay screen loading");
      return;
    }

    const options = {
      key: 'rzp_live_lqIxNnKgsACP64',
      amount: amount,
      currency: 'INR',
      name: "Ruby shopping",
      description: "Payment to Papaya Coders",
      image: "https://papayacoders.com/demo.png",
      handler: function (response) {
        setResponseId(response.razorpay_payment_id);
        fetchPaymentDetails(response.razorpay_payment_id); // Automatically fetch payment details
      },
      prefill: {
        name: "Rubyshopping",
        email: "evilwill73@gmail.com"
      },
      theme: {
        color: "#0dcaf0"
      }
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  // Function to automatically fetch payment details
  const fetchPaymentDetails = (paymentId) => {
    axios.get(`https://api.rubyshoping.online/api/payment/${paymentId}`)
      .then((response) => {
        navigate("/");
        window.location.reload();
      })
      .catch((error) => {
        console.log("Error occurred while fetching payment details:", error);
      });
  };

  // Trigger the payment process automatically when the component mounts
  useEffect(() => {
    if (amt) {
      createRazorpayOrder(amt); // Use the 'amt' from URL parameters
    }
  }, [amt]); // Run this effect when 'amt' is available

  return (
    <div>
      {/* Optionally, display some loading or success message here */}
    </div>
  );
}

export default PaymentSuccess;
