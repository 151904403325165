import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Grid, TextField, IconButton, Drawer,Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import CopyRight from '../../Components/CopyRight/CopyRight';
import Orders from './Orders';
import PasswordUpdate from './PasswordUpdate';
import AddressSection from './AddressCard'; // Import the AddressSection component
import { fetchUserAddresses } from '../ApiService'; // Import the address service
import Footer from '../Policy/Footer'

const UpdateDetails = () => {
  const [activeSection, setActiveSection] = useState('Setting');
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
  });
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const authToken = localStorage.getItem('Authorization');
  const navigate = useNavigate();

  useEffect(() => {
    if (authToken) {
      getUserData();
    } else {
      navigate('/');
    }
  }, [authToken, navigate]);

  const getUserData = async () => {
    try {
      const addresses = await fetchUserAddresses(authToken,'getUserDetails');
      setUserDetails(addresses);
      // Assume the user details are fetched similarly or handled elsewhere
    } catch (error) {
      toast.error('Something went wrong', { autoClose: 500, theme: 'colored' });
    }
  };

  const renderContent = () => {
    switch (activeSection) {
      case 'Setting':
        return (
          <Grid container spacing={2}>
            {[
              { label: 'First Name', name: 'firstName' },
              { label: 'Last Name', name: 'lastName' },
              { label: 'Contact Number', name: 'phoneNumber' },
              { label: 'Email', name: 'email' },
            ].map((field) => (
              <Grid key={field.name} item xs={12} sm={6}>
                <TextField
                  label={field.label}
                  name={field.name}
                  value={userDetails[field.name] || ''}
                  variant='outlined'
                  fullWidth
                />
              </Grid>
            ))}
            <PasswordUpdate authToken={authToken} />;
          </Grid>

        );
      case 'Address':
        return <AddressSection addresses={userDetails.address} />; // Use the AddressSection component
      case 'Orders':
        return <Orders />;
      default:
        return null;
    }
  };

  return (
    <>
    <Container
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'row',
        marginBottom: 10,
        position: 'relative',
      }}
    >
      <IconButton
        sx={{
          display: { xs: 'block', sm: 'none' },
          position: 'fixed',
          top: 10,
          left: 10,
          zIndex: 1000,
        }}
        onClick={() => setIsMenuOpen(true)}
      >
        <MenuIcon />
      </IconButton>

      <Drawer
        anchor="left"
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        sx={{ display: { xs: 'block', sm: 'none' } }}
      >
        <Box
          sx={{
            width: 200,
            padding: '20px',
            bgcolor: '#f4f4f4',
            borderRight: '1px solid #ddd',
            height: '100vh',
          }}
        >
          <IconButton
            sx={{ mb: 2 }}
            onClick={() => setIsMenuOpen(false)}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant='h6'
            sx={{ marginBottom: '20px', fontWeight: 'bold', color: '#1976d2' }}
          >
            Account settings
          </Typography>
          {['Setting', 'Address', 'Orders'].map((item) => (
            <Button
              key={item}
              variant='contained'
              sx={{
                marginBottom: '10px',
                backgroundColor: activeSection === item ? '#1976d2' : '#41576f',
              }}
              onClick={() => {
                setActiveSection(item);
                setIsMenuOpen(false);
              }}
            >
              {item}
            </Button>
          ))}
        </Box>
      </Drawer>

      <Box
        sx={{
          width: '200px',
          height: '100vh',
          bgcolor: '#f4f4f4',
          padding: '20px',
          display: { xs: 'none', sm: 'flex' },
          flexDirection: 'column',
          alignItems: 'flex-start',
          borderRight: '1px solid #ddd',
        }}
      >
        <Typography
          variant='h6'
          sx={{ marginBottom: '20px', fontWeight: 'bold', color: '#1976d2' }}
        >
          Account setting
        </Typography>
        {['Setting', 'Address', 'Orders'].map((item) => (
          <Button
            key={item}
            variant='contained'
            sx={{
              marginBottom: '10px',
              backgroundColor:
                activeSection === item ? '#1976d2' : '#41576f',
            }}
            onClick={() => setActiveSection(item)}
          >
            {item}
          </Button>
        ))}
      </Box>

      <Box
        sx={{
          flex: 1,
          padding: '20px',
          maxWidth: '100%',
          width: { xs: '100%', sm: 'calc(100% - 200px)' },
        }}
      >
        {renderContent()}
      </Box>
      <CopyRight />
      
    </Container>
            <Footer />
            </ >
  );
};

export default UpdateDetails;
