import React from 'react';
import { Grid, Card, CardContent, Typography, Button, Dialog, DialogContent, DialogActions } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify'; // Import toast for notifications
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import AddressFormPage from "../../Components/Checkout/AddressFormPage"; // Import the new address form page component

const AddressSection = ({ addresses, setAddresses }) => {
  const [openPopup, setOpenPopup] = React.useState(false);

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleDeleteAddress = async (addressId) => {
    try {
      const authToken = localStorage.getItem('Authorization'); // Retrieve the token from localStorage
      
      // Make the DELETE request with the Authorization header
      const response = await axios.get(`https://api.rubyshoping.online/api/deleteAddress/${addressId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`, // Pass the token in the Authorization header
        },
      });

      if (response.data.success) {
        // Remove the deleted address from the local state
        // setAddresses((prevAddresses) => prevAddresses.filter((address) => address._id !== addressId));

        // Show success message using toast
        toast.success("Address deleted successfully", {
          autoClose: 500,
          theme: "colored",
        });
        window.location.reload();
      }
    } catch (error) {
      console.error("Error deleting address:", error);
      toast.error("Failed to delete address", {
        autoClose: 500,
        theme: "colored",
      });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        {addresses.length === 0 ? (
          <Grid item xs={12}>
            <Card
              style={{
                background: 'linear-gradient(90deg, rgba(22,78,93,1) 0%, rgba(148,187,233,1) 100%)',
                color: '#fff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                padding: '16px',
              }}
            >
              <Button 
                variant="contained" 
                color="primary"
                onClick={handleOpenPopup}
                style={{
                  backgroundColor: '#1976d2',
                  width: '100%',
                }}
              >
                Add Address
              </Button>
            </Card>
          </Grid>
        ) : (
          addresses.map((address, index) => (
            <Grid key={index} item xs={12} sm={6}>
              <Card
                style={{
                  background: 'linear-gradient(147deg, #f9fcff 0%, #dee4ea 74%)',
                }}
              >
             
                <CardContent>
                  <Typography variant="h6">Address</Typography>
                  <Typography>{address.add}</Typography>
                  <Typography variant="h6">City</Typography>
                  <Typography>{address.city}</Typography>
                  <Typography variant="h6">Zip Code</Typography>
                  <Typography>{address.zipCode}</Typography>
                  <Button 
                    variant="contained" 
                    color="error"  // Change color to red
                    onClick={() => handleDeleteAddress(address._id)}
                    style={{ marginTop: '10px' }}
                  >
                    Delete Address
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))
        )}

        {/* Add New Address Button in the last grid item */}
        {addresses.length > 0 && (
          <Grid item xs={12} sm={6}>
            <Card
              style={{
                background: 'linear-gradient(147deg, #f9fcff 0%, #dee4ea 74%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                padding: '16px',
              }}
            >
              <Button
                      variant="contained"
                      sx={{
                        marginTop: "10px",
                        color:"black",
                        background:
                          "linear-gradient(147deg, #f9fcff 0%, #dee4ea 74%)",
                        "&:hover": {
                          background:
                            "linear-gradient(147deg, #f9fcff 0%, #dee4ea 74%)", // Keep gradient on hover
                        },
                      }}
                      onClick={handleOpenPopup}
                    >
                      Add New Address
                    </Button>
                    
             
            </Card>
          </Grid>
        )}
      </Grid>

      {/* Dialog for the new address form */}
      <Dialog
        open={openPopup}
        onClose={handleClosePopup}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <AddressFormPage />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopup} color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddressSection;
