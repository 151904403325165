import React from "react";
import { Container, Typography } from "@mui/material";

const RefundPolicy = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Refund & Cancellation Policy
      </Typography>

      <Typography variant="body1" paragraph>
        Thank you for shopping at Ruby Shopping. We value your satisfaction and strive to provide you with the best shopping experience. If you are not entirely satisfied with your purchase, we're here to help.
      </Typography>

      <Typography variant="h5" gutterBottom>
        1. Returns
      </Typography>
      <Typography variant="body1" paragraph>
        You have [7] days to return an item from the date you received it. To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.
      </Typography>

      <Typography variant="h5" gutterBottom>
        2. Refunds
      </Typography>
      <Typography variant="body1" paragraph>
        Once we receive your item, we will inspect it and notify you that we have received your returned item. We will immediately notify you of the status of your refund after inspecting the item.
      </Typography>
      <Typography variant="body1" paragraph>
        If your return is approved, we will initiate a refund to your original method of payment. You will receive the credit within a certain amount of days, depending on your card issuer's policies.
      </Typography>

      <Typography variant="h5" gutterBottom>
        3. Late or Missing Refunds
      </Typography>
      <Typography variant="body1" paragraph>
        If you haven’t received a refund yet, first check your bank account again. Then contact your credit card company; it may take some time before your refund is officially posted.
      </Typography>
      <Typography variant="body1" paragraph>
        If you’ve done all of this and you still have not received your refund yet, please contact us at info@rubyshoping.online.
      </Typography>

      <Typography variant="h5" gutterBottom>
        4. Sale Items
      </Typography>
      <Typography variant="body1" paragraph>
        Only regular-priced items may be refunded. Unfortunately, sale items cannot be refunded.
      </Typography>

      <Typography variant="h5" gutterBottom>
        5. Exchanges
      </Typography>
      <Typography variant="body1" paragraph>
        We only replace items if they are defective or damaged. If you need to exchange it for the same item, contact us at info@rubyshoping.online.
      </Typography>

      <Typography variant="h5" gutterBottom>
        6. Shipping
      </Typography>
      <Typography variant="body1" paragraph>
        You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.
      </Typography>
      <Typography variant="body1" paragraph>
        Depending on where you live, the time it may take for your exchanged product to reach you may vary.
      </Typography>

      <Typography variant="h5" gutterBottom>
        7. Cancellation Policy
      </Typography>
      <Typography variant="body1" paragraph>
        You may cancel your order within 24 hours of placing it. To cancel an order, please contact us immediately at info@rubyshoping.online with your order details. If your order has already been shipped, you will need to follow our return process.
      </Typography>
      <Typography variant="body1" paragraph>
        Please note that cancellations are not guaranteed after the 24-hour window, as your order may have already been processed.
      </Typography>

      <Typography variant="h5" gutterBottom>
        8. Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions about our Refund & Cancellation Policy, please contact us at info@rubyshoping.online.
      </Typography>
    </Container>
  );
};

export default RefundPolicy;
