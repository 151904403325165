import React from "react";
import { Container, Typography } from "@mui/material";

const TermsAndConditions = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Terms and Conditions
      </Typography>

      <Typography variant="body1" paragraph>
        Welcome to Ruby Shopping! These terms and conditions outline the rules and regulations for the use of Ruby Shopping's website and services.
      </Typography>

      <Typography variant="h5" gutterBottom>
        1. Introduction
      </Typography>
      <Typography variant="body1" paragraph>
        By accessing this website, we assume you accept these terms and conditions in full. Do not continue to use Ruby ShoppingRuby Shopping if you do not agree to all of the terms and conditions stated on this page.
      </Typography>

      <Typography variant="h5" gutterBottom>
        2. Intellectual Property Rights
      </Typography>
      <Typography variant="body1" paragraph>
        Unless otherwise stated, Ruby Shopping and/or its licensors own the intellectual property rights for all material on this website. All intellectual property rights are reserved. You may view and/or print pages from https://rubyshoping.online/ for your own personal use subject to restrictions set in these terms and conditions.
      </Typography>

      <Typography variant="h5" gutterBottom>
        3. Restrictions
      </Typography>
      <Typography variant="body1" paragraph>
        You are specifically restricted from the following:
      </Typography>
      <ul>
        <Typography component="li" variant="body1">
          Selling, sublicensing, and/or otherwise commercializing any website material.
        </Typography>
        <Typography component="li" variant="body1">
          Using this website in any way that is or may be damaging to this website.
        </Typography>
        <Typography component="li" variant="body1">
          Engaging in any data mining, data harvesting, data extracting, or any other similar activity in relation to this website.
        </Typography>
        <Typography component="li" variant="body1">
          Using this website to engage in any advertising or marketing without our prior consent.
        </Typography>
      </ul>

      <Typography variant="h5" gutterBottom>
        4. Your Account
      </Typography>
      <Typography variant="body1" paragraph>
        If you create an account on our site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer. You agree to accept responsibility for all activities that occur under your account or password.
      </Typography>

      <Typography variant="h5" gutterBottom>
        5. Limitation of Liability
      </Typography>
      <Typography variant="body1" paragraph>
        In no event shall Rubyshoping, nor any of its officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this website, whether such liability is under contract. Ruby Shopping, including its officers, directors, and employees, shall not be held liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this website.
      </Typography>

      <Typography variant="h5" gutterBottom>
        6. Indemnification
      </Typography>
      <Typography variant="body1" paragraph>
        You hereby indemnify to the fullest extent Ruby Shopping from and against any and all liabilities, costs, demands, causes of action, damages, and expenses arising in any way related to your breach of any of the provisions of these terms.
      </Typography>

      <Typography variant="h5" gutterBottom>
        7. Termination
      </Typography>
      <Typography variant="body1" paragraph>
        We may terminate or suspend access to our service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the terms.
      </Typography>

      <Typography variant="h5" gutterBottom>
        8. Governing Law
      </Typography>
      <Typography variant="body1" paragraph>
        These terms will be governed by and interpreted in accordance with the laws of Rubyshoping, and you submit to the non-exclusive jurisdiction of the state and federal courts located in Rubyshoping for the resolution of any disputes.
      </Typography>

      <Typography variant="h5" gutterBottom>
        9. Changes to Terms
      </Typography>
      <Typography variant="body1" paragraph>
      Rubyshoping is permitted to revise these terms at any time as it sees fit, and by using this website you are expected to review these terms on a regular basis.
      </Typography>

      <Typography variant="h5" gutterBottom>
        10. Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions about these Terms, please contact us at info@rubyshoping.online.
      </Typography>
    </Container>
  );
};

export default TermsAndConditions;
