import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box, Paper, Snackbar, Alert } from '@mui/material';
import axios from 'axios';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const response = await axios.get(`https://api.rubyshoping.online/api/contactus/${formData.name}/${formData.email}/${formData.message}`, {
       
        });

      if (response.data.success) {
        setSnackbarMessage('Form submitted successfully!');
        setOpenSnackbar(true);
      } else {
        setSnackbarMessage('There was an issue with your submission.');
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setSnackbarMessage('Error submitting the form. Please try again.');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Container style={{ marginTop: '2rem' }}>
      <Box display="flex" justifyContent="space-between" flexDirection={{ xs: 'column', md: 'row' }}>
        {/* Left Side - Contact Form */}
        <Box flex={1} marginRight={{ md: '2rem' }}>
          <Typography variant="h6" gutterBottom>
            Contact Us
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
              multiline
              rows={4}
            />
            <Button type="submit" variant="contained" color="primary" style={{ marginTop: '1rem',alignItems:'center' }}>
              Submit
            </Button>
          </form>
        </Box>

        {/* Right Side - Contact Information */}
        <Box flex={1} marginTop={{ xs: '2rem', md: '0' }}>
          <Paper elevation={3} style={{ padding: '1.5rem', backgroundColor: '#f9f9f9',marginTop:'4rem',marginBottom:'5rem' }}>
            <Typography variant="h6" gutterBottom>
              Contact Information
            </Typography>
            <Typography variant="body2" color="textSecondary" paragraph>
              Email Id: info@rubyshoping.online
            </Typography>
            <Typography variant="body2" color="textSecondary" paragraph>
              Address: Road/Street: NILMATHA CANTT, Nearby CENTRAL PUBLIC SCHOOL, Lucknow
              <br />
              District: Lucknow
              <br />
              State: Uttar Pradesh
              <br />
              PIN Code: 226002
            </Typography>
          </Paper>
        </Box>
      </Box>

      {/* Snackbar for notifications */}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ContactUs;
