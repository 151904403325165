import React from "react";
import { Container, Typography } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>

      <Typography variant="body1" paragraph>
        Welcome to Ruby Shopping. This Privacy Policy outlines the types of personal information we collect, how we use it, and the steps we take to ensure it is protected.
      </Typography>

      <Typography variant="h5" gutterBottom>
        1. Information We Collect
      </Typography>
      <Typography variant="body1" paragraph>
        We collect personal information that you provide to us directly when you register, place an order, or contact us. This includes your name, email address, phone number, billing and shipping addresses, and payment information.
      </Typography>

      <Typography variant="h5" gutterBottom>
        2. How We Use Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        The information we collect is used to:
      </Typography>
      <ul>
        <Typography component="li" variant="body1">
          Process and fulfill your orders
        </Typography>
        <Typography component="li" variant="body1">
          Communicate with you about your orders, account, and inquiries
        </Typography>
        <Typography component="li" variant="body1">
          Improve our products, services, and website functionality
        </Typography>
        <Typography component="li" variant="body1">
          Send promotional emails, if you have opted in to receive them
        </Typography>
      </ul>

      <Typography variant="h5" gutterBottom>
        3. Sharing Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        We do not sell, trade, or otherwise transfer your personal information to outside parties, except for trusted third parties who assist us in operating our website, conducting our business, or servicing you, as long as those parties agree to keep this information confidential.
      </Typography>

      <Typography variant="h5" gutterBottom>
        4. Security
      </Typography>
      <Typography variant="body1" paragraph>
        We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee its absolute security.
      </Typography>

      <Typography variant="h5" gutterBottom>
        5. Cookies
      </Typography>
      <Typography variant="body1" paragraph>
        We use cookies to enhance your experience on our site. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your web browser that enables the site's systems to recognize your browser and capture and remember certain information.
      </Typography>

      <Typography variant="h5" gutterBottom>
        6. Your Rights
      </Typography>
      <Typography variant="body1" paragraph>
        You have the right to access, correct, or delete your personal information, as well as the right to object to or restrict certain processing of your data. To exercise these rights, please contact us at info@rubyshoping.online.
      </Typography>

      <Typography variant="h5" gutterBottom>
        7. Changes to Our Privacy Policy
      </Typography>
      <Typography variant="body1" paragraph>
        We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this page periodically for the latest information on our privacy practices.
      </Typography>

      <Typography variant="h5" gutterBottom>
        8. Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions about this Privacy Policy, please contact us at info@rubyshoping.online.
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;
