import axios from 'axios';

const API_URL = 'https://api.rubyshoping.online/api';

export const fetchUserAddresses = async (authToken,getUserDetails) => {

  console.log("getUserDetails",getUserDetails)
  try {
    const { data } = await axios.get(`${API_URL}/${getUserDetails}`, {
      headers: { Authorization: authToken },
    });
    return data.result || [];
  } catch (error) {
    throw new Error('Failed to fetch addresses');
  }
};
