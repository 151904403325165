import { Typography } from '@mui/material'
import React from 'react'

const CopyRight = (props) => {
    return (

            <Typography variant="body1" fontWeight="bold" color="text.secondary" align="center"  style={{ color: '#1976d2',  }}>
                
            </Typography>
    )
}

export default CopyRight