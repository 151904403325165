import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  Box,
  Card,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AddressFormPage from "./AddressFormPage";
import "./CheckoutForm.css";
import handleOrderSubmit from "./OrderSubmission";

const CheckoutForm = () => {
  const [useraddress, setUseraddress] = useState([]);
  const [userdetails, setDetails] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(-1);
  const [openPopup, setOpenPopup] = useState(false);

  const authToken = localStorage.getItem("Authorization");
  const setProceed = authToken ? true : false;
  const navigate = useNavigate();
  const totalAmount = sessionStorage.getItem("totalAmount");
  
  useEffect(() => {
    if (setProceed) {
      getPreviousOrder();
    } else {
      navigate("/");
    }
  }, [setProceed, navigate]);

  useEffect(() => {
    // Automatically select the first address if none is selected
    if (useraddress.length > 0 && selectedAddress === -1) {
      setSelectedAddress(0);
    }
  }, [useraddress, selectedAddress]);

  const getPreviousOrder = async () => {
    try {
      const { data } = await axios.get(
        `https://api.rubyshoping.online/api/getUseraddress`,
        {
          headers: {
            Authorization: authToken,
          },
        }
      );
      setDetails(data.result.user);
      setUseraddress(data.result.address);
    } catch (error) {
      console.error("Error fetching user address:", error);
    }
  };

  const handleEdit = (index) => {
    console.log("Edit address:", useraddress[index]);
  };

  const handleDelete = async (index) => {
    try {
      await axios.delete(
        `https://api.rubyshoping.online/api/deleteUseraddress/${useraddress[index]._id}`,
        {
          headers: {
            Authorization: authToken,
          },
        }
      );
      getPreviousOrder();
    } catch (error) {
      console.error("Error deleting address:", error);
    }
  };

  const handleAddNewAddress = () => {
    setSelectedAddress(-1);
    setOpenPopup(true);
  };

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    if (value === -1) {
      handleAddNewAddress();
    } else {
      setSelectedAddress(value);
    }
  };

  const handleDeliver = () => {
    console.log("Deliver to address:", useraddress[selectedAddress]);
  };

  const handleCheckoutClick = async () => {
    // Automatically select the first address if none is selected
    if (selectedAddress < 0 && useraddress.length > 0) {
      setSelectedAddress(0);
    }

    if (selectedAddress < 0) {
      alert("Please select or add an address before proceeding.");
      return;
    }
    
    const orderSuccess = await handleOrderSubmit(useraddress[selectedAddress]);
    console.log("orderSuccess", orderSuccess.orderId);
    if (orderSuccess) {
      let amt= parseFloat(totalAmount) + 1 + 1
      navigate(`/PaymentSuccess/${amt}/${orderSuccess.orderId}`);
    } else {
      alert("Order failed. Please try again.");
    }
  };

  return (
    <Container>
      <Dialog
        open={openPopup}
        onClose={handleClosePopup}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <AddressFormPage />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopup} color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={3}>
        {/* Full-width Select dropdown */}
        <Grid item xs={12}>
          <FormControl fullWidth className="full-width-select">
            <InputLabel id="address-select-label">Select Address</InputLabel>
            <Select
              labelId="address-select-label"
              value={selectedAddress}
              onChange={handleSelectChange}
              label="Select Address"
            >
              {useraddress.map((address, index) => (
                <MenuItem key={index} value={index}>
                  Address {index + 1}
                </MenuItem>
              ))}
              <MenuItem value={-1}>
                <Button
                  variant="contained"
                  className="full-width-button"
                  onClick={handleAddNewAddress}
                >
                  Add New Address
                </Button>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Address Details and Price Details */}
        <Grid item xs={12} md={8}>
          {useraddress.length === 0 ? (
            <Grid item xs={12}>
              <Card
                style={{
                  background:
                    "linear-gradient(147deg, #f9fcff 0%, #dee4ea 74%)",
                  color: "black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  padding: "16px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenPopup}
                  style={{
                    backgroundColor: "#1976d2",
                    width: "100%",
                  }}
                >
                  Add Address
                </Button>
              </Card>
            </Grid>
          ) : (
            selectedAddress >= 0 && (
              <Grid
                container
                spacing={3}
                style={{
                  marginLeft: "0.5rem",
                }}
              >
                {useraddress[selectedAddress] && (
                  <Grid
                    item
                    xs={11}
                    className="address-card"
                    style={{ marginTop: "2rem" }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: "bold" }}
                    >
                      Address {selectedAddress + 1}
                    </Typography>
                    <Typography variant="body1" style={{ marginTop: "8px" }}>
                      {useraddress[selectedAddress].add}
                    </Typography>
                    <Typography variant="body1" style={{ marginTop: "8px" }}>
                      Name {useraddress[selectedAddress].firstName}{" "}
                      {useraddress[selectedAddress].lastName}
                    </Typography>
                    <Typography variant="body1" style={{ marginTop: "4px" }}>
                      Mobile No. {useraddress[selectedAddress].phoneNumber}
                    </Typography>
                    <Typography variant="body1" style={{ marginTop: "4px" }}>
                      Email Id {userdetails.email}
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{
                        marginTop: "10px",
                        background:
                          "linear-gradient(147deg, #f9fcff 0%, #dee4ea 74%)",
                        color: "black", // Ensure the text color is readable on the gradient background
                        "&:hover": {
                          background:
                            "linear-gradient(147deg, #f9fcff 0%, #dee4ea 74%)", // Keep gradient on hover
                        },
                      }}
                      onClick={handleDeliver}
                    >
                      Selected Address
                    </Button>
                  </Grid>
                )}
              </Grid>
            )
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          <Box
            padding="15px"
            borderRadius="8px"
            boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
            className="price-details"
          >
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Price Details
            </Typography>
            <Typography variant="body1" style={{ marginTop: "8px" }}>
              Delivery Fees: 1
            </Typography>
            <Typography variant="body1" style={{ marginTop: "8px" }}>
              GST: 2
            </Typography>
            <Typography variant="body1" style={{ marginTop: "8px" }}>
              Total Amount: ₹{parseFloat(totalAmount) + 1 + 2 || "0.00"}
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCheckoutClick}
            style={{
              marginTop: "1rem",
              backgroundColor: "#1976d2",
              width: "100%",
              marginBottom: "6rem",
            }}
          >
            Checkout
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CheckoutForm;
