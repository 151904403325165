import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SessionCheck = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const authToken = localStorage.getItem("Authorization");

    if (!authToken) {
      navigate("/login"); // Redirect to the login page if no auth token is found
    }
  }, [navigate]);

  return (
    <>
      {children} {/* Render the children components if the session is active */}
    </>
  );
};

export default SessionCheck;
