import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  Stepper,
  Step,
  StepLabel,
  Box,
  Button,
  Badge,
} from "@mui/material";
import { AiFillShopping } from "react-icons/ai";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [expandedOrder, setExpandedOrder] = useState(null); // Track expanded order
  const authToken = localStorage.getItem("Authorization");

  useEffect(() => {
    const getCart = async () => {
      if (authToken) {
        try {
          const { data } = await axios.get(
            `https://api.rubyshoping.online/api/getorder`,
            {
              headers: {
                Authorization: authToken,
              },
            }
          );
          console.log("data.result", data.result);
          setOrders(data.result);
        } catch (error) {
          console.error("Error fetching cart data:", error);
          toast.error("Failed to load cart data", {
            autoClose: 500,
            theme: "colored",
          });
        }
      } else {
        console.log("No auth token found");
      }
    };

    getCart();
  }, [authToken]);

  const getStatusLabel = (status) => {
    switch (status) {
      case 1:
        return "Order is Processing";
      case 2:
        return "Order Delivered";
      case 3:
        return "Order Canceled";
      case 4:
        return "Payment Failed";
      default:
        return "Unknown Status";
    }
  };

  const getActiveStep = (status) => {
    switch (status) {
      case 1:
        return 0; // Processing
      case 2:
        return 1; // Delivered
      case 3:
        return 2; // Canceled
      default:
        return 0; // Default to Processing
    }
  };

  const handleReadMoreClick = (orderId) => {
    setExpandedOrder(expandedOrder === orderId ? null : orderId);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        marginTop: "1rem",
      }}
    >
      {orders.length === 0 ? (
        <Card>
          <CardContent>
            <Typography variant="h6" align="center">
              No orders available
            </Typography>
          </CardContent>
        </Card>
      ) : (
        orders.map((order) => (
          <Card
            key={order._id}
            sx={{
              display: "flex",
              flexDirection: "column",
              mb: 2,
              "@media (min-width:600px)": { flexDirection: "row" },
            }}
          >
            {/* Additional image on the left side of the card for larger screens */}
            {order.products[0].image && (
              <CardMedia
                component="img"
                sx={{
                  width: "100%",
                  height: { xs: "150px", sm: "200px" },
                  objectFit: "cover",
                  "@media (min-width:600px)": { width: "200px" },
                }}
                image={order.products[0].image} // Ensure this field exists in your API response
                alt={`Additional image for Order ID ${order._id}`}
              />
            )}
            <Box
              sx={{ flex: 1, display: "flex", flexDirection: "column", p: 2 }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{
                    borderBottom: "2px solid black", // Add the outline below the product name
                    paddingBottom: "4px", // Space between the text and the outline
                    fontSize: { xs: "16px", sm: "18px" }, // Adjust font size for mobile
                  }}
                >
                  {`${order.products[0].productName}, `}
                  {order.products.length > 1 && (
                    <Badge badgeContent={order.products.length}>
                      <AiFillShopping className="nav-icon" />
                    </Badge>
                  )}
                </Typography>

                <Typography
                  variant="h6"
                  sx={{ fontSize: { xs: "14px", sm: "16px" } }}
                >
                  Status: {getStatusLabel(order.status)}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontSize: { xs: "14px", sm: "16px" } }}
                >
                  Total Price: ${order.totalPrice}
                </Typography>

                {/* Container for Read More button and Stepper */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    mt: 2,
                  }}
                >
                  <Button
                    onClick={() => handleReadMoreClick(order._id)}
                    variant="outlined"
                    sx={{ fontSize: { xs: "12px", sm: "14px" } }} // Adjust button font size
                  >
                    {expandedOrder === order._id ? "Show Less" : "Read More"}
                  </Button>

                  {expandedOrder === order._id && (
                    <Box sx={{ mt: 2, width: "100%" }}>
                      {order.products.map((product) => (
                        <Box
                          key={product._id}
                          sx={{
                            mb: 1,
                            display: "flex",
                            gap: 2,
                            flexDirection: "row", // Set the default direction to row
                            flexWrap: "wrap", // This ensures items wrap to the next line if they don't fit
                            alignItems: "center", // Vertically center items
                          }}
                        >
                          <Link
                            to={`/Detail/type/${product?.type}/${product?._id}`}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              textDecoration: "none",
                            }}
                          >
                            <CardMedia
                              sx={{
                                width: { xs: "100%", sm: "200px" }, // 100% width on small screens, 200px on larger screens
                                height: { xs: "150px", sm: "200px" },
                                objectFit: "cover",
                              }}
                              image={product.image}
                              alt={`Additional image for Order ID ${order._id}`}
                            />
                            <Box sx={{ ml: 2 }}>
                              <Typography
                                variant="h6"
                                sx={{ fontSize: { xs: "14px", sm: "16px" } }}
                              >
                                {product.productName}
                              </Typography>
                              <Typography
                                sx={{ fontSize: { xs: "12px", sm: "14px" } }}
                              >
                                Price: ${product.price}
                              </Typography>
                              <Typography
                                sx={{ fontSize: { xs: "12px", sm: "14px" } }}
                              >
                                Quantity: {product.quantity}
                              </Typography>
                            </Box>
                          </Link>
                        </Box>
                      ))}

                      <Box sx={{ mt: 2 }}>
                        <Stepper
                          activeStep={getActiveStep(order.status)}
                          alternativeLabel
                        >
                          <Step key="processing">
                            <StepLabel
                              sx={{ fontSize: { xs: "12px", sm: "14px" } }}
                            >
                              Processing
                            </StepLabel>
                          </Step>
                          <Step key="delivered">
                            <StepLabel
                              sx={{ fontSize: { xs: "12px", sm: "14px" } }}
                            >
                              Delivered
                            </StepLabel>
                          </Step>
                        </Stepper>
                      </Box>

                      <Box sx={{ mt: 2 }}>
                        {order.matchingAddresses.length > 0 ? (
                          order.matchingAddresses.map((address) => (
                            <Box key={address._id} sx={{ mb: 1 }}>
                              <Typography
                                sx={{ fontSize: { xs: "12px", sm: "14px" } }}
                              >
                                <strong>Address:</strong> {address.add}
                              </Typography>
                              <Typography
                                sx={{ fontSize: { xs: "12px", sm: "14px" } }}
                              >
                                <strong>City:</strong> {address.city}
                              </Typography>
                              <Typography
                                sx={{ fontSize: { xs: "12px", sm: "14px" } }}
                              >
                                <strong>ZIP Code:</strong> {address.zipCode}
                              </Typography>
                              <Typography
                                sx={{ fontSize: { xs: "12px", sm: "14px" } }}
                              >
                                <strong>Phone:</strong> {address.phoneNumber}
                              </Typography>
                              <Typography
                                sx={{ fontSize: { xs: "12px", sm: "14px" } }}
                              >
                                <strong>Contact Person:</strong>{" "}
                                {address.firstName}
                              </Typography>
                            </Box>
                          ))
                        ) : (
                          <Typography
                            sx={{ fontSize: { xs: "12px", sm: "14px" } }}
                          >
                            No address details available
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </CardContent>
            </Box>
          </Card>
        ))
      )}
    </Box>
  );
};

export default Orders;
