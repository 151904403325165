import React from "react";
import { Container, Typography } from "@mui/material";

const Disclaimer = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Disclaimer
      </Typography>

      <Typography variant="body1" paragraph>
        The information provided by Ruby Shopping ("we," "us," or "our") on our website is for general informational purposes only. All information on the site is provided in good faith, however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the site.
      </Typography>

      <Typography variant="h5" gutterBottom>
        1. External Links Disclaimer
      </Typography>
      <Typography variant="body1" paragraph>
        Our website may contain (or you may be sent through the site) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us. We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the site or any website or feature linked in any banner or other advertising.
      </Typography>

      <Typography variant="h5" gutterBottom>
        2. Professional Disclaimer
      </Typography>
      <Typography variant="body1" paragraph>
        The site cannot and does not contain professional advice. The information is provided for general informational and educational purposes only and is not a substitute for professional advice. Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals. We do not provide any kind of professional advice. The use or reliance of any information contained on this site is solely at your own risk.
      </Typography>

      <Typography variant="h5" gutterBottom>
        3. Limitation of Liability
      </Typography>
      <Typography variant="body1" paragraph>
        Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the site or reliance on any information provided on the site. Your use of the site and your reliance on any information on the site is solely at your own risk.
      </Typography>

      <Typography variant="h5" gutterBottom>
        4. Changes to this Disclaimer
      </Typography>
      <Typography variant="body1" paragraph>
        We may update this Disclaimer from time to time in order to reflect changes to our practices or for other operational, legal, or regulatory reasons. You are encouraged to review this Disclaimer periodically for any updates.
      </Typography>

      <Typography variant="h5" gutterBottom>
        5. Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions about this Disclaimer, please contact us at info@rubyshoping.online.
      </Typography>
    </Container>
  );
};

export default Disclaimer;
