import React from "react";
import { Container, Typography, Box, List, ListItem, Link } from "@mui/material";

const ShippingAndDelivery = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Shipping & Delivery
      </Typography>

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          Shipping Policy
        </Typography>
        <Typography variant="body1" paragraph>
          At RubyShoping, we are committed to delivering your order as quickly as possible. We process orders within 1-2 business days, and shipping times vary depending on your location and the shipping method selected at checkout.
        </Typography>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          Delivery Times
        </Typography>
        <Typography variant="body1" paragraph>
          Estimated delivery times are as follows:
        </Typography>
        <List>
          <ListItem>
            <Typography variant="body1">• Standard Shipping: 5-7 business days</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">• Express Shipping: 2-3 business days</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">• Overnight Shipping: 1 business day</Typography>
          </ListItem>
        </List>
        <Typography variant="body1" paragraph>
          Please note that delivery times are estimates and may vary due to external factors such as weather conditions or carrier delays.
        </Typography>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          International Shipping
        </Typography>
        <Typography variant="body1" paragraph>
          We offer international shipping to selected countries. Delivery times and shipping costs vary by destination. Please note that international orders may be subject to customs duties and taxes, which are the responsibility of the customer.
        </Typography>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          Tracking Your Order
        </Typography>
        <Typography variant="body1" paragraph>
          Once your order has been shipped, you will receive a confirmation email with a tracking number. You can track your order online using this tracking number.
        </Typography>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          Customer Service
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions or concerns about your order, please contact our customer service team at{" "}
          <Link href="mailto:info@rubyshoping.online">info@rubyshoping.online</Link>. We are here to assist you 24/7.
        </Typography>
      </Box>
    </Container>
  );
};

export default ShippingAndDelivery;
