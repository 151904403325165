import axios from 'axios';

const handleOrderSubmit = async (address) => {
  try {
    // Retrieve cart items from sessionStorage
    const cartItems = JSON.parse(sessionStorage.getItem("cartItems")) || [];

    // Check if the cart is empty
    if (cartItems.length === 0) {
      alert('Your cart is empty. Please add items to your cart before placing an order.');
      return false; // Return false to indicate failure
    }
    const authToken = localStorage.getItem("Authorization");

    const products = cartItems.map(item => ({
      productId: item._id,
      quantity: 1,  // Adjust quantity as needed
      price: item.price
    }));

    // Create the order data object
    const orderData = {
      products,
      address // Use the address passed in
    };

    // Send the order data to the backend API
    const response = await axios.post('https://api.rubyshoping.online/api/order', orderData, {
        headers: {
            Authorization: authToken,
        },
    });

    // Handle the response from the server
    if (response.data.success) {
      alert('Order placed successfully');
      // Clear the cart after successful order
      sessionStorage.removeItem("cartItems");
      return response.data;  // Return true to indicate success
    } else {
      alert('Failed to place order');
      return false; // Return false to indicate failure
    }
  } catch (error) {
    console.error("There was an error placing the order!", error);
    alert('Server error. Please try again later.');
    return false; // Return false to indicate failure
  }
};

export default handleOrderSubmit;
