import { Container } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate,Link } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { AiFillCloseCircle, AiOutlineLogin } from "react-icons/ai";
import { EmptyCart } from "../../Assets/Images/Image";
import { Transition } from "../../Constants/Constant";
import CopyRight from "../../Components/CopyRight/CopyRight";
import './Wishlist.css'; // Import the CSS file
import Footer from '../Policy/Footer'

const Wishlist = () => {
  const [wishlistData, setWishlistData] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const navigate = useNavigate();

  const authToken = localStorage.getItem("Authorization");
  const setProceed = authToken ? true : false;

  useEffect(() => {
    const getWishList = async () => {
      if (setProceed) {
        try {
          const { data } = await axios.get(
            `https://api.rubyshoping.online/api/getWishlist`,
            {
              headers: {
                Authorization: authToken,
              },
            }
          );
          console.log("data received", data.result);
          setWishlistData(data.result || []);
        } catch (error) {
          // toast.error("Failed to fetch wishlist", {
          //   autoClose: 500,
          //   theme: "colored",
          // });
        }
      } else {
        setOpenAlert(true);
      }
    };
    getWishList();
  }, [authToken, setProceed]);

  const removeFromWishlist = async (product) => {
    console.log("product", product._id);
    if (setProceed) {
      try {
        const deleteProduct = await axios.get(
          `https://api.rubyshoping.online/api/deleteToWishlist/${product._id}`,
          {
            headers: {
              Authorization: authToken,
            },
          }
        );
        console.log("deleteProduct.data.success", deleteProduct.data.success);
        if (deleteProduct.data.success) {
          toast.success("Removed From Wishlist", {
            autoClose: 500,
            theme: "colored",
          });
          window.location.reload();

        } else {
          // Handle the scenario where the operation does not succeed
          toast.error("Failed to remove from Wishlist", {
            autoClose: 500,
            theme: "colored",
          });
        }
      } catch (error) {
        // Ensure only errors from the catch block trigger the error toast
        console.error("Error removing item:", error);
        toast.error("An error occurred while removing the item from your wishlist.", {
          autoClose: 500,
          theme: "colored",
        });
      }
    }
  };
  

  const handleClose = () => {
    setOpenAlert(false);
    navigate("/");
  };

  const handleToLogin = () => {
    navigate("/login");
  };

  return (
    <>
    <Container>
      {wishlistData.length > 0 ? (
        <Box className="wishlist-container">
          {wishlistData.map((product) => (
                                              <Link to={`/Detail/type/${product?.type}/${product?._id}`}>

            <Box key={product._id} className="wishlist-item">
              <img src={product.image} alt={product.name} />
              <Typography variant="h6">{product.name}</Typography>
              <Typography variant="h6">price {product.price}</Typography>
              <Typography variant="h6">Items left {product.items_left}</Typography>

              <Button
                variant="contained"
                color="secondary"
                onClick={() => removeFromWishlist(product)}
              >
                Remove
              </Button>
            </Box>
            </Link>
          ))}
        </Box>
      ) : (
        <Box textAlign="center">
            <img src={EmptyCart} alt="Empty Cart" className="empty-cart-image1" />
        </Box>
      )}

      <Dialog
        open={openAlert}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogContent>
          <Typography>Please login to view your wishlist.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToLogin} startIcon={<AiOutlineLogin />}>
            Login
          </Button>
          <Button onClick={handleClose} startIcon={<AiFillCloseCircle />}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <CopyRight />
    </Container>
                <Footer />
                </ >
  );
};

export default Wishlist;
